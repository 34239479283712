import ProductsSlider, { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import { useSponsoredProducts } from 'hooks/criteo/useSponsoredProducts';
import { useIntl } from 'react-intl';
import { SimplePlacement } from 'types/Criteo';

type SliderProps = Pick<ProductSliderProps, 'alwaysShowArrows' | 'slidesObject' | 'showSignings'>;

interface SponsoredProductsSliderProps extends SliderProps {
  id?: string;
  placements: SimplePlacement[];
  showSignings?: boolean;
  showTitle?: boolean;
  className?: string;
}

const SponsoredProductsSlider = ({
  id,
  placements,
  showSignings,
  showTitle,
  ...sliderProps
}: SponsoredProductsSliderProps) => {
  const { formatMessage } = useIntl();

  const { isLoading, mappedPlacements, onViewSponsoredProducts } = useSponsoredProducts(placements);
  const sponsoredProducts = mappedPlacements?.[0]?.items;

  if (!sponsoredProducts?.length) return null;

  const onViewFormat = () => {
    onViewSponsoredProducts(placements[0]);
  };

  return (
    <ProductsSlider
      id={id}
      isLoading={isLoading}
      onView={onViewFormat}
      products={sponsoredProducts}
      showSignings={showSignings}
      title={showTitle ? formatMessage({ id: 'sponsored_products_title' }) : undefined}
      {...sliderProps}
    />
  );
};
export default SponsoredProductsSlider;
