import { useProductQuery } from 'features';
import { useCriteoTracking } from 'hooks/useCriteoTracking';
import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import { SimplePlacement } from 'types/Criteo';
import { Product } from 'types/Product';
import { log } from 'utils/loggerUtil';

export const useSponsoredProducts = (placements?: SimplePlacement[]) => {
  const { asPath } = useRouter();
  const { pushCriteoBeacon } = useCriteoTracking();
  const criteoProductIds = placements?.flatMap((placement) => placement.items.map((product) => product.id)) ?? [];
  const productQuery = criteoProductIds.map((productId) => `:code:${productId}`).join('');
  const { data: fetchedProducts, isLoading } = useProductQuery({ query: productQuery });

  const mappedPlacements = placements?.map((placement) => {
    const items = placement.items.reduce((acc: Product[], item) => {
      const product = fetchedProducts?.products?.find((fetchedProduct) => fetchedProduct.code === item.id);
      if (!product) return acc;

      const mappedProduct: Product = {
        ...product,
        isSponsored: true,
        legalInfo: item.legalInfo,
        tracking: {
          OnClickBeacon: item.onClickUrl,
          OnLoadBeacon: item.onLoadUrl,
          OnViewBeacon: item.onViewUrl,
          OnWishlistBeacon: item.onWishlistUrl,
        },
      };

      return [...acc, mappedProduct];
    }, []);

    return {
      ...placement,
      items,
    };
  });

  const sponsoredProducts = mappedPlacements?.flatMap((placement) => placement.items) ?? [];

  useEffect(() => {
    if (!pushCriteoBeacon) return;
    if (placements?.length) {
      placements.forEach((placement) => {
        if (placement.onLoadUrl) {
          log(`Criteo - SP - ${asPath}`, 'pushing OnLoadBeacon for format', placement.onLoadUrl);
          pushCriteoBeacon(placement.onLoadUrl);
        }

        placement.items.forEach((product) => {
          if (product.onLoadUrl) {
            log(`Criteo - SP - ${asPath}`, `pushing OnLoadBeacon for SP product with code: ${product?.id}`);
            pushCriteoBeacon(product.onLoadUrl);
          }
        });
      });
    }
  }, [placements]);

  const onViewSponsoredProducts = (placement: SimplePlacement) => {
    if (!pushCriteoBeacon) return;
    if (placement.onViewUrl) {
      log(`Criteo - SP - ${asPath}`, 'pushing OnViewBeacon for format', placement.onViewUrl);
      pushCriteoBeacon(placement.onViewUrl);
    }
  };

  return { isLoading, mappedPlacements, onViewSponsoredProducts, sponsoredProducts };
};
